import React from "react";

export default function Header() {
    return (
        <section id="header">
            <div className="container px-5 py-10 mx-auto justify-center text-center lg:px-40">
                <div className="flex flex-col w-full mt-20 mb-10">
                    <h1 className="font-serif text-6xl md:text-8xl mb-4 font-medium text-black">
                        Berkeley Chimneys
                    </h1>
                    <p className="text-black text-2xl font-serif font-medium"> Serving the Bay Area since 1992. </p>
                    <p className="text-red text-4xl font-serif mt-2 font-medium"> <a href="tel:PHONE_NUM"> (510) 528-1002 </a> </p>
                </div>
                <div className="flex flex-col w-full mt-35 justify-center">
                    <p className="text-black text-2xl font-serif font-medium"> Offering consultations, maintenance and repairs, water proofing, and chimney removal services. </p>
                    <div className="flex flex-row justify-center">
                        <a href="#about" className="text-red text-xl md:text-2xl font-serif m-4 hover:underline"> About </a>
                        <a href="#services" className="text-red text-xl md:text-2xl font-serif m-4 hover:underline"> Services </a>
                        <a href="#contact" className="text-red text-xl md:text-2xl font-serif m-4 hover:underline"> Contact us </a>
                    </div>
                    <a href="https://www.yelp.com/biz/berkeley-chimneys-albany"><img src={process.env.PUBLIC_URL + "yelp.png"} className="mx-auto justify-center mb-40 w-[110px] h-[70px]" alt="Yelp" /></a>
                </div>
            </div>
        </section >
    );
}