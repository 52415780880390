import React, { useState, useEffect } from 'react';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
        // Honeypot field (should be empty for real users)
        honeypot: ''
    });
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.honeypot || (!formData.message || !formData.message.includes(' '))) {
            console.log('Bot detected');
            return;
        }

        // Clear form fields
        setFormData({
            name: '',
            email: '',
            phone: '',
            message: '',
            honeypot: '' // Reset honeypot field
        });
        setIsSubmitted(true);

        try {
            const response = await fetch('https://berkeleychimneys-2c8416666594.herokuapp.com/api/submit-contact-form', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log('Form submitted successfully');
            } else {
                console.error('Form submission failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        if (isSubmitted) {
            const timer = setTimeout(() => {
                setIsSubmitted(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [isSubmitted]);

    const isButtonDisabled = formData.honeypot || !formData.message || !formData.message.includes(' ');

    return (
        <section id="contact" className="bg-red py-20">
            <div className="bg-white rounded-lg h-auto py-6 lg:py-16 px-10 mx-auto max-w-screen-md">
                <h2 className="mb-4 text-4xl font-medium text-center text-black font-serif">Contact us</h2>
                <p className="mb-4 text-md text-center font-light font-sans">Call us at (510) 528-1002 or fill out the form below!</p>
                <form onSubmit={handleSubmit} className="space-y-6 text-center">
                    <div>
                        <label htmlFor="name" className="block mb-2 text-left text-md text-black font-light font-sans">Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block mb-2 text-left text-md text-black font-light font-sans">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="phone" className="block mb-2 text-left text-md text-black font-light font-sans">Phone number</label>
                        <input
                            type="phone"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                        />
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="message" className="block mb-2 text-md text-left text-black font-light font-sans">Your message</label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            rows="4"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            required
                        />
                    </div>

                    {/* honeypot field (invisible to real users) */}
                    <div style={{ display: 'none' }}>
                        <label htmlFor="honeypot">Leave this field empty</label>
                        <input
                            type="text"
                            id="honeypot"
                            name="honeypot"
                            value={formData.honeypot}
                            onChange={handleChange}
                            className="hidden"
                        />
                    </div>

                    {!isSubmitted && (
                        <button
                            disabled={isButtonDisabled}
                            type="submit"
                            className={`p-4 rounded-lg text-white font-light font-sans ${
                                isButtonDisabled
                                    ? "bg-red cursor-not-allowed opacity-50"
                                    : "bg-red hover:outline hover:outline-1 hover:outline-red-500 hover:bg-white hover:text-red" 
                            }`}
                        >
                            Send message
                        </button>
                    )}
                    {isSubmitted && (
                        <p className="mt-4 text-black font-light font-sans">Thanks for your message!</p>
                    )}
                </form>
            </div>
        </section>
    );
};

export default Contact;
