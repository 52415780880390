import React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Carousel,
  IconButton
} from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}

export default function Services2() {
  const [openMain, setOpenMain] = React.useState(0);
  const [openNested, setOpenNested] = React.useState(0);

  const handleOpenMain = (value) => setOpenMain(openMain === value ? 0 : value);
  const handleOpenNested = (value) => setOpenNested(openNested === value ? 0 : value);

  return (
    <section id="services" className="text-black text-center scroll-mt-20 flex flex-col">
      <div className="bg-white rounded-lg container mx-auto px-10 py-10 flex-grow md:w-3/4">
        <div className="flex flex-col w-full">
          <h1 className="sm:text-4xl text-3xl font-serif font-medium text-black pb-5">Services</h1>
          <div className="text-left">
            <Accordion open={openMain === 1} icon={<Icon id={1} open={openMain} />}> 
              <AccordionHeader onClick={() => handleOpenMain(1)} className="text-2xl font-serif font-medium text-black">
                Consultations
              </AccordionHeader>
              <AccordionBody className="text-lg text-black ">
              For many new home owners as well as established residents, a big question is what should I do with my chimney? Is it presently safe to use? Is it structurally sound? Is it a seismic hazard? Should I convert to gas or is an electric fireplace the way forward? Should I remove some or all of the chimney structure? What are my options and how much do they cost? With over 30 years of experience, we can answer these questions. 
              </AccordionBody>
            </Accordion>
            <Accordion open={openMain === 2} icon={<Icon id={2} open={openMain} />}> 
              <AccordionHeader onClick={() => handleOpenMain(2)} className="text-2xl font-serif font-medium text-black">
                Repairs
              </AccordionHeader>
              <AccordionBody className="text-lg text-black ">
                Whether the chimney is used or not it may need masonry repairs, waterproofing, cap and damper replacements or just cleaning. We can evaluate what is needed and take care of any problems.
              </AccordionBody>
            </Accordion>
            <Accordion open={openMain === 3} icon={<Icon id={3} open={openMain} />}> 
              <AccordionHeader onClick={() => handleOpenMain(3)} className="text-2xl font-serif font-medium text-black">
                Chimney removal
              </AccordionHeader>
              <AccordionBody className="text-lg text-black ">
              It is an unfortunate fact that at some point most Bay Area chimneys are coming down one way or another. As we transition away from wood burning, many people are opting to pre-emptively remove this obvious seismic hazard. Almost no chimneys built before 1940 have any steel reinforcement and masonry strength and integrity diminishes with age. For many people, having a one ton brick tower perched over their house is a seismic risk they can do without. Every chimney is different just as every client is unique. What is appropriate in one situation may not be in another. We all have different priorities and different budgets. We offer affordable chimney removal, full or partial and estimates for this service are generally free of charge.
                <Accordion open={openNested === 5} icon={<Icon id={5} open={openNested} />}> 
                  <AccordionHeader onClick={() => handleOpenNested(5)} className="text-xl font-serif font-medium text-black">
                    Partial removal
                  </AccordionHeader>
                  <AccordionBody className="text-lg text-black ">
                  The section of the chimney above the roofline is the most vulnerable part of the structure. Roofline fractures followed by collapse is the most common damage incurred by chimneys in a significant earthquake. Falling masonry obviously poses a serious hazard to people, as well as roofs, neighboring houses and cars parked in driveways. Lowering chimneys to just above the roofline eliminates this hazard and is the most cost-effective preventative measure that most home owners can take. The cost of the job rises significantly when taken down lower as the roof, flashing and siding will need to be repaired too. Lowering the chimney height usually means that it can no longer be used for wood burning, but it can still house a gas insert or an electric fireplace without changing the internal appearance. The fireplace is often the focal point of a living room, used or not. It provides an ambience that, if removed, can really alter the whole character of the room. Lowering the chimney to the roofline is our most popular service, and surprisingly inexpensive.
                  </AccordionBody>
                </Accordion>
                <Accordion open={openNested === 6} icon={<Icon id={6} open={openNested} />}> 
                  <AccordionHeader onClick={() => handleOpenNested(6)} className="text-xl font-serif font-medium text-black">
                    Full removal
                  </AccordionHeader>
                  <AccordionBody className="text-lg text-black ">
                  The full removal of the chimney is often undertaken if there is significant damage below the roofline, or if the foundation has slipped or failed. Some people simply want to remodel or create more space. In most circumstances the mantle, hearth and facing cannot be saved as it is all structurally connected.. We certainly offer this service and can organize the additional repairs. In some cases, however, it may be wiser to employ a general contractor as the extra work needed to the floor, wall and roof may be much more work than the initial removal of the chimney.
                  </AccordionBody>
                </Accordion>
              </AccordionBody>
            </Accordion>
            <Accordion open={openMain === 4} icon={<Icon id={4} open={openMain} />}> 
              <AccordionHeader onClick={() => handleOpenMain(4)} className="text-2xl font-serif font-medium text-black">
                Costs
              </AccordionHeader>
              <AccordionBody className="text-lg text-black">
              Estimates and consultations are generally free of charge unless required for legal or insurance purposes, or for use in the buying or selling of a house. Consultations and reports for those services generally cost between $250 and $450. <br/>
Chimney cleanings and inspection cost $295 in most cases. <br/>
Waterproofing usually costs between $450 and $850. <br/>
Masonry repairs, caps and dampers etc., can only be assessed on a case by case basis. <br/>
Partial chimney removal to the roofline can cost between $1250 and $5000 depending on the situation. Full chimney removal can cost upwards of $5000, again depending on the circumstances and size of the project. <br/>
These prices are meant as a guideline only and we advise a free on site estimate to understand the specific cost of your project. Please call us anytime.
              </AccordionBody>
            </Accordion>
          </div>
        </div>
      </div>
      <Carousel
  className="my-5 w-3/4 md:w-1/2 mx-auto overflow-hidden" 
  loop={true}
  navigation={({ setActiveIndex, activeIndex, length }) => (
    <div className="absolute bottom-2 left-2/4 z-50 flex -translate-x-2/4 gap-2 bg-white py-2 px-2 rounded-md">
      {new Array(length).fill("").map((_, i) => (
        <span
          key={i}
          className={`block h-1 cursor-pointer rounded-full transition-all content-[''] 
            ${activeIndex === i 
              ? "w-4 bg-red"  
              : "w-2 bg-red/50"} 
          `}
          onClick={() => setActiveIndex(i)}
        />
      ))}
    </div>
  )}
  
  prevArrow={({ handlePrev }) => (
    <IconButton
      size="lg"
      onClick={handlePrev}
      className="!absolute top-2/4 left-1 md:left-2 -translate-y-2/4 bg-white rounded-full p-2"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="#8f1402"
        className="h-6 w-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
        />
      </svg>
    </IconButton>
  )}
  
  nextArrow={({ handleNext }) => (
    <IconButton
      size="lg"
      onClick={handleNext}
      className="!absolute top-2/4 !right-1 md:!right-2 -translate-y-2/4 bg-white rounded-full p-1"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="#8f1402"
        className="h-6 w-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
        />
      </svg>
    </IconButton>
  )}
>
{Array.from({ length: 41 }, (_, index) => (
  <div
    key={index}
    className="flex items-center justify-center min-h-[200px] md:min-h-[400px] mx-2" 
  >
    <img
      src={`${process.env.PUBLIC_URL}/chimneys/ch${index + 1}.jpg`}
      alt={`Chimney ${index + 1}`}
      className="max-h-[200px] md:max-h-[400px]" 
    />
  </div>
))}

</Carousel>

    </section>
  );
}