import React from "react";

export default function About() {
    return (
        <section id="about" className="text-white scroll-mt-20 flex flex-row">
            <div className="hidden md:block flex-grow">
                <img src={process.env.PUBLIC_URL + '/nick.jpeg'} alt="Logo" className="w-full h-full object-cover" />
            </div>
            <div className="bg-red container py-10 mx-auto text-center px-10 flex-grow">
                <div className="flex flex-col w-full">
                    <h1 className="sm:text-4xl font-serif text-3xl title-font mb-4 text-white">
                        About us
                    </h1>
                    <p className="text-lg text-justify mx-auto font-sans font-light pt-5">
                        Berkeley Chimneys was established by Nick Duggan in 1992. We are fully insured and bonded state licensed contractors with an unblemished record and a reputation for honesty, reliability, and great work. We are also certified by the Chimney Safety Institute of America.
                        With over 30 years of experience in the Bay Area, you can feel confident in our knowledge, advice, and exceptional work. Safety and customer satisfaction are always our primary goals.
                    </p>
                    <br />
                    <p className="text-lg text-justify mx-auto font-sans font-light">
                        As we transition away from wood burning, many people are left wondering what they should do with their chimneys. We are here to answer those questions and carry out the work that you finally decide upon.
                        We are proud of our reputation and encourage you to check out our five star reviews on Yelp, Angie's, or any other local referral services.
                    </p>
                </div>
            </div>
        </section >
    );
}
